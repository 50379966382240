@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Outfit:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

.dark .apexcharts-gridlines-horizontal .apexcharts-gridline {
  stroke: #3d475c !important;
}
input {
  font-family: "Inter" !important;
}
.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background: #3f51b5;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #fff;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #fff;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}
.controls {
  z-index: 9999999;
  position: absolute;
  bottom: 14px;
  width: 96%;
  left: 8px;
}
.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.formikCheck {
  position: relative;
  top: 5px;
}

.dark .partnerTable.Dashboard table thead tr td {
  color: #8ea8cd !important;
}
.dark .dashboardBottom {
  background: #222530 !important;
}
.dark .dashboardBottom span {
  color: #8ea8cd !important;
}
.dark .dashboardBottom button {
  border: 1px solid #72849d !important;
  color: #fff !important;
  font-family: "Inter";
  border-radius: 8px !important;
}
.dark .partnerTable.Dashboard td .firstTd .ant-space-item {
  color: #8ea8cd !important;
}
.dark .financeTable table thead tr th {
  color: #8ea8cd !important;
}
.dark .financeTable table tbody td:nth-child(3) {
  color: #8ea8cd !important;
  font-weight: 400 !important;
}
.dark .financeTable table tbody td:nth-child(1) {
  color: #8ea8cd !important;
  font-weight: 500 !important;
}
.dark .financeTable table tbody td:nth-child(4) {
  color: #8ea8cd !important;
  font-weight: 500 !important;
}
.dark .financeTable table tbody td:nth-child(5) {
  color: #8ea8cd !important;
  font-weight: 500 !important;
}
.dark .financeTable table tbody td:nth-child(7) button span {
  color: #fff !important;
  font-weight: 500 !important;
}
.dark .financeTable table tbody td:nth-child(2) {
  color: #fff !important;
  font-weight: 500 !important;
}
.dark .addMores {
  color: #2970ff !important;
  font-size: 14px !important;
  font-weight: 500;
}
.dark .paidStatus {
  background: #095c2d !important;
  color: #11ff84 !important;
}
.addMores {
  font-size: 14px !important;
  font-weight: 500;
}
.dark .partnerTable.finance span.ant-tag.ant-tag-green.colors {
  background-color: #095c2d !important;
  color: #11ff84 !important;
}
.financeTable table tbody td:nth-child(7) {
  text-align: end !important;
}
.dark .firstTd .ant-tag.ant-tag-blue {
  background: #467127 !important;
  color: #bbe49e !important;
}
.dark .financeTable {
  border: 1px solid #3d475c !important;
}
.dark .firstTd .ant-tag.ant-tag-green {
  background: #185b77 !important;
  color: #7fc5e3 !important;
}
.partnerTable.Dashboard td:nth-child(5) {
  text-align: end !important;
}
.dark .partnerTable thead tr td {
  background-color: #222530 !important;
}
.dark .partnerTable.Dashboard table th {
  color: #8ea8cd !important;
}
.dark .apexcharts-gridline {
  stroke: #3d475c !important;
}
.dark .apexcharts-grid-borders line {
  stroke: #3d475c !important;
}
.apexcharts-gridline {
  stroke: #f2f4f7 !important;
}
.apexcharts-grid-borders line {
  stroke: #f2f4f7 !important;
}
.dark .apexcharts-tooltip {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c) !important;
  color: white !important; /* Set the text color to white */
  border-radius: 5px; /* Optional: round the corners */
}
.dark .headingContent {
  color: #8ea8cd !important;
}
.dark .dateRange input::placeholder {
  color: #fff !important;
}
.ant-picker:hover .ant-picker-suffix:not(:last-child) {
  opacity: 1 !important;
}
.apexcharts-xaxis-tick {
  transform: translate(10px, 63px) !important;
}
.dark .apexcharts-tooltip-title {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c) !important;
  color: white !important; /* Make the title text white */
}
.light .lightimg {
  position: absolute;
  bottom: 12px;
  display: block;
  z-index: 9;
  left: 10px;
}
.dark .adminHeader {
  background: #0e121b !important;
  border-bottom: 1px solid var(--Lighter-Dark, #3d475c) !important;
}
.ant-picker-panel-container.ant-picker-year-panel-container {
  border: 1px solid #f2f4f7;
  border-radius: 8px;
  box-shadow: 0 8px 8px -4px #10182808;
  box-shadow: 0 20px 24px -4px #10182814;
  width: 372px;
}
.dark td.ant-picker-cell.ant-picker-cell-disabled {
  color: #8ea8cd !important;
}
.dark .ant-empty-description {
  color: #8ea8cd !important;
}
.dark .ant-picker-cell-inner {
  color: #8ea8cd !important;
}
.dark .ant-picker-decade-btn {
  color: #fff !important;
}
.dark .ant-picker-panel-container.ant-picker-year-panel-container {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c);
}
.ant-picker-panel-layout {
  justify-content: center;
  padding: 15px 15px 8px !important;
}
.ant-picker-year-panel {
  width: 320px !important;
}
.ant-picker-year-panel .ant-picker-header {
  border: 0 !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}
td.ant-picker-cell.ant-picker-cell-disabled {
  color: #667085;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.3;
}
body.light .passwordTheme input {
  padding-left: 26px !important;
  font-family: "Inter";
}
.dark
  td.ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  background: transparent !important;
  color: #155eef !important;
}
.light
  .ant-picker-cell.ant-picker-cell-hover.ant-picker-cell-selected.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  background: #fff !important;
  color: #155eef !important;
  font-size: 14px !important;
  font-weight: 400;
}
.dark .partnerTable table {
  background-color: #222530 !important;
}
.tabsSelect .MuiTabs-indicator {
  color: #4885ff !important;
}
.tabsSelect .Mui-selected {
  color: #4885ff !important;
}
.dark .ant-picker-super-prev-icon {
  background-image: url(./assets/images/les.png);
}
.dark .ant-picker-super-next-icon {
  background-image: url(./assets/images/res.png);
}
.ant-picker-super-prev-icon {
  background-image: url(./assets/images/la.png);
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBfZA9DkBAEIXfrp/aERzBFdyEQq/XaESrlCgcxRE4giPoJZjdLIk16zU7s9/Le8kIMMrKJgmB8QRyn4MBMBGEh32THDRr2nf1Klxw6KpFDeIPakNWtnGAY+agkiQYPVGM9H9BFfRMajntintwmV7JnOlTbZukbTDRKeFVXfICA/87B36br4kAAAAASUVORK5CYII=); */
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px !important;
  transform: rotate(1turn) !important;
  width: 12px !important;
}
span.ant-picker-super-prev-icon::before {
  display: none !important;
}
.ant-picker-super-prev-icon::after {
  display: none !important;
}
.ant-picker-super-next-icon::before {
  display: none !important;
}
.ant-picker-super-next-icon::after {
  display: none !important;
}
.ant-picker-super-next-icon {
  background-image: url(./assets/images/ra.png);
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACMSURBVHgBdZAxDkBAEEX/EGpHcARXcBMKvV6jEa1S4hCO4AgcwRH0EszISuxkd4rdnbw3s5Ohou7TGNd8A+U0NBtUBBGuhGFKwFLVXaYFksOARRKW838n+h4+if7tXBLpP7UUaOE0N0OEOA9LKLg64mqT5uPQ7uSD1pA++AqySV7W6oIS7yYJ9+6CEg802z+7tgYOLgAAAABJRU5ErkJggg==); */
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px !important;
  transform: rotate(1turn) !important;
  width: 12px !important;
}
.ant-picker-dropdown .ant-picker-cell-disabled::before {
  background-color: transparent !important;
}
.ant-picker-cell-inner {
  font-family: "Inter";
}
.ant-picker-dropdown .ant-picker-content td {
  border: none !important;
}
.recentlyViewBox {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dark .recentlyViewBox .recentBox span {
  color: #8ea8cd !important;
}
.dark .recentlyViewBox .recentBox {
  background-color: #222530 !important;
}
.recentlyView {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.dark table th::before {
  display: none !important;
}

.dark table th {
  border-bottom: 1px solid #3d475c !important;
}

.dark table td {
  border-bottom: 1px solid #3d475c !important;
}

.light .partnerTable.edits table thead th {
  background: #f9fafb !important;
}

.notificationBoxed h5 {
  color: #667085;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 400;
  font-family: "Inter";
}

.notificationBoxed {
  border-bottom: 1px solid #eaecf0;
  border-radius: 0;
}

.dismiss.Btn {
  padding: 14px 0;
}

.dismiss.Btn span {
  font-size: 14px;
  font-weight: 600;
  color: #475467;
  margin-right: 12px;
  font-family: "Inter";
}

.dismiss.Btn a {
  color: #004eeb;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter";
}

.notificationBoxed h6 {
  font-size: 14px;
  font-weight: 600;
  color: #101828;
  font-family: "Inter";
}

.notificationBoxed p {
  color: #475467;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 420;
}

.textRight {
  text-align: end;
  padding-bottom: 6px;
}

.textRight h5 {
  color: #475467 !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.notificationBoxed {
  position: absolute;
  width: 400px;
  max-width: 400px;
  right: 0;
  top: 46px;
  background: #fff;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;

  border-radius: 12px;
  padding: 10px;
}

.searchInput {
  width: 320px;
  height: 44px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  color: #101828;

  padding-left: 36px;
  font-family: "Inter";
}
.searchInput::placeholder {
  color: #667085;
}

.dark .mobile {
  display: none;
}

.dark .leftForm label {
  color: #fff !important;
}

.dark .leftForm input {
  color: #667085;
  opacity: 1;
}

.light .darkimag {
  display: none;
}

.dark .lightimag {
  display: none;
}

.dark .notificationBoxed .ant-row {
  background-color: transparent !important;
  border-bottom: 1px solid #667085 !important;
}

.dark .notificationBoxed h5 {
  color: #667085 !important;
}

.dark .notificationBoxed p {
  color: #8ea8cd !important;
}

.dark .dismiss.Btn span {
  color: #667085 !important;
}

.dark .dismiss.Btn a {
  color: #4885ff !important;
}

.dark .notificationBoxed {
  background-color: #222530 !important;
  border: 1px solid var(--Lighter-blue, #282b39) !important;
}

.dark .leftForm textarea {
  background-color: #0e121b !important;
  border: 1px solid #8794b1;
}

.dark .leftForm input::placeholder {
  color: #667085;
  opacity: 1;
}

.dark .boxedContact {
  background: #222530 !important;
  border: 1px solid #3d475c !important;
}

.searchInput::placeholder {
  color: #667085;
}

.dark .searchInput::placeholder {
  color: #8ea8cd;
}

.dark .searchInput {
  width: 320px;
  height: 44px;
  border: 1px solid #0e121b;
  border-radius: 8px;
  color: #8ea8cd;
  font-family: "Inter";
}

.dark .passwordTheme .ant-input-affix-wrapper {
  background: #0e121b !important;
  border: 1px solid #8794b1 !important;
}

.dark .passwordTheme .ant-input-affix-wrapper input {
  border: 0 !important;
  font-family: "Inter";
}

.dark .passwordTheme input {
  color: #72849d !important;
  padding-left: 23px !important;
}

.dark .anticon svg {
  color: #667085 !important;
}

.dark .anticon svg path {
  fill: #667085 !important;
}

.dark .lightimg {
  position: absolute;
  bottom: 12px;
  display: none;
  z-index: 9;
  left: 10px;
}

.dark .darkBorder {
  border-top-width: 0 !important;
  border-top: 1px solid #72849d !important;
}

.light .darkimg {
  position: absolute;
  bottom: 12px;
  z-index: 9;
  display: none;
  left: 10px;
}

.dark .darkimg {
  position: absolute;
  bottom: 12px;
  z-index: 9;
  display: block;
  left: 10px;
}
.dark .pText {
  color: #8ea8cd !important;
}
.light .mobile {
  display: none;
}

.light .desktop {
  display: block;
}

.dark .spans {
  color: #72849d !important;
}

.dark h1 {
  color: #fff !important;
}

.dark h2 {
  color: #fff !important;
}

.dark h3 {
  color: #fff !important;
}

.dark h4 {
  color: #fff !important;
}

.dark h5 {
  color: #fff !important;
}

.dark h6 {
  color: #fff !important;
}

.dark .desktop {
  display: none;
}

.dark .mobile {
  display: block;
}

.dark .ant-row {
  background-color: #0e121b !important;
}

.dark .google {
  background-color: #2e384d !important;
  color: #fff !important;
  border: 0 !important;
}

.dark .signups {
  border: 0 !important;
}

.dark p {
  color: #fff;
}

.dark .sds {
  background: #3d475c !important;
}

.dark .sds span {
  color: #fff !important;
}

.dark .headers button span {
  color: #667085;
}

.dark span {
  color: #fff;
}

/* .dark .selectInput div {
  color: #fff !important;
} */

.dark .checkAgree label {
  margin-right: 6px !important;
}
.dark .checkAgree .ant-checkbox-inner {
  background-color: #3d475c !important;
  border: 1px solid #72849d !important;
}
.dark .checkAgree {
  margin: 12px 0 4px;
  /* display: inline-flex;
  align-items: flex-start; */
  color: #8ea8cd !important;
  font-size: 14px !important;
  font-family: "Inter";
}
.firstTd .ant-tag.ant-tag-green {
  border-radius: 30px !important;
  background: #eeffe3 !important;
  width: 135px !important;
  height: 28px !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #93d365 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}
.firstTd {
  display: flex;
  align-items: center;
}
.firstTd .ant-tag.ant-tag-blue {
  border-radius: 30px !important;
  background: #ecf9ff !important;
  width: 135px !important;
  height: 28px !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7fc5e3 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}
.firstTd .ant-space-item {
  color: #101828 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
  width: 128px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.partnerTable.finance td:nth-child(3) {
  color: #475467 !important;
  font-weight: 400 !important;
}
.statusPayment {
  display: inline-flex;
  width: 54px;
  height: 28px;
  background: #ecfdf3;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #027a48;
  font-size: 14px;
  font-weight: 500;
  margin: 8px 0;
}
.partnerTable.finance td:nth-child(4) {
  color: #475467 !important;
  font-weight: 400 !important;
}
.partnerTable.finance td:nth-child(5) {
  color: #475467 !important;
  font-weight: 400 !important;
}
.partnerTable.finance td:nth-child(7) button span {
  color: #475467 !important;
  font-weight: 600 !important;
}
.partnerTable.finance .ant-tag.ant-tag-orange {
  background: #fffaeb !important;
  color: #b54708 !important;
  height: 28px;
  font-size: 14px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  border-radius: 16px !important;
  padding: 0 11px !important;
  border: 0 !important;
}
.partnerTable.Dashboard .ant-tag.ant-tag-orange {
  height: 28px;
  background: #fffaeb !important;
  border: 0 !important;
  color: #b54708 !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  font-size: 14px !important;
  border-radius: 16px !important;
  padding: 0 11px !important;
}
.tabsSelect.partners {
  margin-bottom: 0px !important;
}
.partnerTable.finance span.ant-tag.ant-tag-green.colors {
  background-color: #ecfdf3 !important;
  color: #027a48 !important;
  height: 28px;
  font-size: 14px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  border-radius: 16px !important;
  padding: 0 11px !important;
  border: 0 !important;
}
.partnerTable.Dashboard span.ant-tag.ant-tag-green.colors {
  background-color: #ecfdf3 !important;
  color: #027a48 !important;
  height: 28px;
  font-size: 14px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content !important;
  border-radius: 16px !important;
  padding: 0 11px !important;
  border: 0 !important;
}
body .partnerTable.Dashboard td .ant-btn-link {
  border: 0 !important;
  color: #475467 !important;
}
.partnerTable.Dashboard td:nth-child(1) {
  width: 328px !important;
}
.partnerTable ul.ant-pagination {
  display: none !important;
}
.labelCount {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* width: 230px; */
}
.checkAgree label {
  margin-right: 6px !important;
}
.checkAgree .ant-checkbox-inner {
  /* background-color: transparent !important; */
  border: 1px solid #d0d5dd !important;
}
.dark .checkAgree span {
  font-weight: 600 !important;
  color: #155eef !important;
}
.checkAgree span {
  font-weight: 600 !important;
  color: #155eef !important;
}
.checkAgree {
  margin: 12px 0 4px;
  /* display: inline-flex;
  align-items: flex-start; */
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter";
}
.dark .buttonss span {
  color: #8ea8cd !important;
}
.dark .loginHave {
  color: #4885ff !important;
  font-weight: 600 !important;
}
.dark .accountHave {
  color: #8ea8cd !important;
  font-size: 14px !important;
  border: 0 !important;
}
.dark .custom-select__control {
  background: transparent !important;
}
.dark h6.charterPassword {
  color: #72849d !important;
}
.dark input.custom-select__input {
  border: 0 !important;
  background-color: transparent !important;
}
.dark .phoneNumber .ant-input-group-wrapper {
  border: 1px solid #8794b1 !important;
  background-color: #0e121b !important;
}

.dark .custom-select__single-value {
  opacity: 1;
  color: #fff !important;
  z-index: 99999;
}
.dark .forgots {
  color: #8ea8cd !important;
}
.checkEmail h1 {
  font-family: "Inter" !important;
}
.dark .boxedNEw {
  background-color: #282b39 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c) !important;
  box-shadow: 0px 2px 4px -2px #1018280f;

  box-shadow: 0px 4px 8px -2px #1018281a;
}
.dark .checkEmail .ant-btn-primary span {
  color: #fff !important;
}
.dark .lenk {
  color: #8ea8cd !important;
}
.dark .lenk span.font-semibold {
  color: #4885ff !important;
}
.dark .checkEmail span {
  color: #8ea8cd;
}
.dark .bottoms span {
  color: #8fa5c3 !important;
}
.dark .phoneNumber .ant-input-group-wrapper input {
  border: 0 !important;
}
.dark .worries {
  color: #8ea8cd;
}
.dark .logBox span {
  color: #8ea8cd !important;
}
.dark .resetBox {
  background: #282b39 !important;
  border: 1px solid #3d475c;
}
.dark .resets {
  color: #4885ff !important;
}
.dark .topBorder {
  border-top: 1px solid var(--Subtitle, #72849d);
}
.dark .coverImage {
  border: 1px solid var(--Subtitle, #72849d);
}
.dark .coverImage .font-semibold {
  color: #4885ff !important;
}
.dark input {
  background-color: #0e121b !important;
  border: 1px solid #8794b1 !important;
  color: #72849d !important;
  font-family: "Inter";
  outline: 0 !important;
}

.dark .adminHeader .container {
  background: #0e121b !important;
  height: 73px;
  border-bottom: 1px solid #3d475c !important;
}

.dark .dashboardright {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c);
}

.dark .linkBoxed h3 {
  color: #8ea8cd !important;
}

.dark .apexcharts-legend-text {
  color: #8ea8cd !important;
}

.dark .linkBoxed .ant-row {
  background: transparent !important;
}

.dark .copyBtn button span {
  color: #8ea8cd !important;
}

.dark .listData h6 {
  color: #8ea8cd !important;
}

.dark .commisionTag button span {
  color: #667085;
}

.dark .commisionTag button {
  background: #222530 !important;
  border: 1px solid #3d475c !important;
}

.dark .partnerTable thead tr th {
  background-color: #222530 !important;
}

.dark .partnerTable thead tr th span {
  color: #8ea8cd !important;
}

.dark .dateSelect span {
  color: #8ea8cd !important;
}

.dark .tabsSelect {
  border-bottom: 1px solid #667085 !important;
}

.dark .showDate {
  background: #222530 !important;
  border: 1px solid var(--Subtitle, #72849d) !important;
}

.dark .cardes .grid {
  background: #222530 !important;
  border: 1px solid #3d475c;
}

.dark .commisionTag .bg-transparent span {
  color: #fff !important;
}

.mobile {
  display: none;
}

.apexcharts-legend-series {
  margin-bottom: 6px !important;
}

span.apexcharts-legend-text {
  color: #475467 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  top: 20px !important;
  right: 5px !important;
}

.dark .commisionTag .bg-transparent {
  background-color: #72849d !important;
  border: 0 !important;
}

.dark .darkcopy {
  display: none;
}

.copyBtn button {
  background: #fff !important;
}

.copyBtn button span {
  color: #344054 !important;
}

.dark .copyBtn input {
  border-top: 1px solid #72849d !important;
  border-left: 1px solid #72849d !important;
  border-right: 0px solid #72849d !important;
  border-bottom: 1px solid #72849d !important;
  background-color: transparent !important;
  color: #72849d !important;
}

.copyBtn button img {
  margin-right: 5px !important;
}

.light .darkcopy {
  display: block;
}

.light .lightcopy {
  display: none;
}

.dark .lightcopy {
  display: block;
}

.dark .listData h6 span {
  color: #8ea8cd !important;
}

.dark .listData::before {
  display: none !important;
}

.dark .dateRange input {
  border: 0 !important;
  background-color: transparent !important;
  color: #fff !important;
}

.dark .dateRange {
  background: transparent !important;
  border: 1px solid #72849d !important;
  color: #fff !important;
}

.dark .copyBtn button {
  background: transparent !important;

  border: 1px solid var(--Subtitle, #72849d) !important;
}

.dark .labelCard {
  color: #8ea8cd;
}

.dark .apexcharts-yaxis-texts-g text {
  fill: #8ea8cd !important;
  transform: translate(-4px, -8px);
}
.dark .apexcharts-xaxis-texts-g text {
  fill: #8ea8cd !important;
}

.dark .linkBoxed {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c);
}

.dark body {
  background-color: #0e121b;
}

.dark .dashboardCard {
  background: #222530 !important;
  border: 1px solid var(--Lighter-Dark, #3d475c);
}

.dark .partnerTable tbody tr td {
  background-color: #282b39 !important;
  color: #8ea8cd !important;
}

.dark .partnerBox {
  border: 1px solid #3d475c !important;
  border-radius: 12px !important;
}

.dark .blackText {
  background: #502003 !important;
  color: #ff6811 !important;
}

.dark .innerPartner thead tr th::before {
  display: none;
}

.dark .inviteTag {
  background: #667085 !important;
}

.dark .innerPartner thead tr td::before {
  display: none;
}

.dark .innerPartner tbody tr td {
  background-color: #282b39 !important;
  color: #8ea8cd !important;
  height: 72px;
  border-bottom: 1px solid #3d475c !important;
}

.dark .tabsTheme .active {
  background: #3d475c !important;
}

.dark .tabsTheme .active span {
  color: #fff !important;
}

.dark .changes {
  border: 1px solid #155eef !important;
  background-color: #155eef !important;
}

.dark .cancel {
  border: 1px solid #3d475c !important;
  background: transparent !important;
}

.dark .tabsBox {
  background: #282b39;
}

.dark .tabsTheme button {
  background: transparent !important;
}

.dark .tabsTheme button span {
  background: transparent !important;
  color: #667085 !important;
}

.dark .tabsTheme {
  background: #0e121b !important;
  border-bottom: 1px solid #3d475c !important;
}

.dark .innerPartner thead tr th {
  background-color: #222530 !important;
  border-bottom: 1px solid #3d475c !important;
  color: #8ea8cd !important;
}

.dark .innerPartner .ant-checkbox-inner {
  background-color: #282b39 !important;
  border: 1px solid #8ea8cd !important;
}

.dark .innerPartner thead tr td {
  background-color: #222530 !important;
  border-bottom: 1px solid #3d475c !important;
  color: #8ea8cd !important;
}

.dark .theme {
  background: #0e121b !important;
}

.dark .blueText {
  background: #095c2d !important;
  color: #11ff84 !important;
}

.dark .partnerBottom {
  background: #222530 !important;
}

.dark .partnerBottom button {
  border: 1px solid #72849d !important;
  color: #fff;
}

.dark .partnerBottom span {
  color: #8ea8cd !important;
}

.dark .dashboardCard div {
  background: #222530 !important;
}

.dark .inviteHead h3 {
  color: #fff !important;
}

.dark .gridBox label {
  color: #fff;
}

.dark .headerView h3 {
  color: #fff !important;
}

.dark .headerView {
  border-bottom: 1px solid #8794b1 !important;
}

.dark .headerView h6 {
  color: #8ea8cd !important;
}

.dark .gridBox input::placeholder {
  color: #72849d;
}

.dark .gridBox input {
  background-color: transparent !important;
  border: 1px solid #8794b1 !important;
  color: #72849d;
}

.dark .modalViewPopup .ant-modal-content {
  background: #222530;
}

.dark .ant-dropdown-menu {
  background: #222530;
  border: 0 !important;
}

.dark .modalBtn {
  border-top: 0px solid #3d475c;
}

.dark .inviteBody label {
  color: #fff;
}

.dark .inviteBody input {
  border: 1px solid #8794b1 !important;
  background-color: transparent !important;
  border-radius: 8px !important;
}
.dark .modalBtn {
  border-top: 1px solid #3d475c !important;
}
.dark .inviteBody {
  border: 0;
}

.dark .inviteHead {
  border-bottom: 1px solid #3d475c !important;
}

.dark .invitationModal .ant-modal-content {
  padding: 0 !important;
  background: #222530;
}

/* .app.light {
  background-color: #ffffff;
  color: #000000;
} */

.dark {
  background-color: #0e121b;
  height: 100%;
  min-height: 100vh;
  color: #ffffff;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f9fafb;
  font-family: "Inter", sans-serif !important;
}

.invitationModal {
  position: relative;
}
.profilePhoto {
  width: 64px !important;
  height: 64px !important;
  border-radius: 100px !important;
  min-width: 64px !important;
  max-width: 64px !important;
}
.profilePhoto img {
  width: 64px !important;
  height: 64px !important;
  border-radius: 100px !important;
  min-width: 64px !important;
  max-width: 64px !important;
}
.profileInput {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  color: #101828;
}
.passwordInput {
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border-radius: 8px !important;
  color: #101828;
  border: 1px solid #d0d5dd !important;
}
.passwordInput input::placeholder {
  color: #667085 !important;
}
.inviteBody input {
  color: #101828;
  height: 44px;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter", sans-serif !important;
}

h3.partnerHeading {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  top: 10px;
}

.invitationModal span.anticon.anticon-delete {
  margin: 0 0px 0 10px !important;
}

.inviteBody button {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  color: #2970ff !important;
  font-size: 14px !important;
  font-weight: 500;
  /* margin: 10px 0 0; */
}

.inviteBody input::placeholder {
  color: #344054 !important;
}

.inviteBody label {
  display: block;
  color: #344054;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
  padding-bottom: 6px;
}

.inviteBody {
  padding: 24px 24px 0px !important;
  border: 1px solid #eff1f4;
  border-bottom: 0 !important;
}

.invitationModal .ant-modal-close-x {
  display: none !important;
}

.light
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  ul {
  padding-left: 0 !important;
  border-right: 1px solid #eaecf0 !important;
}

.light .custom-calendar-class .ant-picker-header-prev-btn {
  display: none !important;
}

.light .custom-calendar-class .ant-picker-header-next-btn {
  display: none !important;
}

.light .custom-calendar-class .ant-picker-header {
  border-bottom: 0 !important;
}

.light .custom-calendar-class .ant-picker-content thead tr th {
  color: #344054 !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.light
  .custom-calendar-class
  td.ant-picker-cell.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  color: #344054;
  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}
.ant-picker.ant-picker-range {
  font-family: "Inter";
}
.light
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #155eef !important;
  border-radius: 100px !important;
  color: #fff !important;
}

.light
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
  height: 40px;
}

.light .custom-calendar-class .ant-picker-content td {
  padding: 0 !important;
}

.light .custom-calendar-class .ant-picker-header-super-next-btn {
  visibility: inherit !important;
}

.light .custom-calendar-class .ant-picker-header-super-prev-btn {
  visibility: inherit !important;
}

.light .custom-calendar-class .ant-input::placeholder {
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.light .custom-calendar-class .ant-picker-footer-extra .ant-btn-default {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600;
}

.light .custom-calendar-class .ant-picker-footer-extra {
  position: relative !important;
}

.light .custom-calendar-class .ant-picker-footer-extra .ant-btn-primary {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
  background-color: #155eef !important;
}

.light .custom-calendar-class .ant-input {
  border: 1px solid #d0d5dd !important;
  color: #101828;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 44px !important;
  font-family: "Inter";
  width: 136px;
  margin-right: 28px;
  border-radius: 8px;
}

.light
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: "." !important;
  border: 0 !important;
  color: #155eef;
  position: absolute;
  top: 10px;
  font-size: 40px !important;
}

.light .custom-calendar-class .ant-picker-footer-extra::before {
  content: "";
  background-image: url(./assets/images/Text.png);
  width: 12px !important;
  position: absolute !important;
  height: 2px;
  bottom: 38px;
  left: 156px;
}

.light .custom-calendar-class .ant-picker-footer-extra .w-full.py-4 {
  display: flex !important;
  align-items: baseline !important;
  padding: 10px 0 0 !important;
  justify-content: space-between !important;
}

.light .custom-calendar-class .ant-picker-panels {
  padding-top: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.light .custom-calendar-class .ant-picker-panel-layout {
  padding: 0 14px !important;
}

.light
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}

.light
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}

.light
  .custom-calendar-class
  .ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  border-radius: 100px !important;
  background-color: #155eef !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  color: #fff !important;
  justify-content: center;
}

.light .custom-calendar-class td.ant-picker-cell .ant-picker-cell-inner {
  color: #667085;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.light .custom-calendar-class .ant-picker-year-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}

.light .custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
}

.light
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  li {
  color: #344054 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.light
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container {
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #f2f4f7 !important;
  border-radius: 8px !important;
}

.light
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  .ant-picker-presets {
  width: 170px !important;
}

.dark
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  ul {
  padding-left: 0 !important;
  border-right: 1px solid #3d475c !important;
}

.dark .custom-calendar-class .ant-picker-header-prev-btn {
  display: none !important;
}

.dark .custom-calendar-class .ant-picker-header-next-btn {
  display: none !important;
}

.dark .custom-calendar-class .ant-picker-header {
  border-bottom: 0 !important;
}

.dark .custom-calendar-class .ant-picker-content thead tr th {
  color: #8ea8cd !important;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  border: 0 !important;
}

.dark
  .custom-calendar-class
  td.ant-picker-cell.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  color: #8ea8cd;

  font-family: "Inter" !important;
  font-size: 14px !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400 !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  )
  .ant-picker-cell-inner {
  background-color: #155eef !important;
  border-radius: 100px !important;
  color: #fff !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
  height: 40px;
}

.dark .custom-calendar-class .ant-picker-content td {
  padding: 0 !important;
  border: 0 !important;
}

.dark .custom-calendar-class .ant-picker-header-super-next-btn {
  visibility: inherit !important;
}

.dark .custom-calendar-class .ant-picker-header-super-prev-btn {
  visibility: inherit !important;
}

.dark .custom-calendar-class .ant-input::placeholder {
  color: #8ea8cd !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

.dark .custom-calendar-class .ant-picker-footer-extra .ant-btn-default {
  background-color: transparent !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #72849d;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600;
}
.dark .ant-picker.ant-picker-range.ant-picker-outlined {
  background: #222530 !important;
  border: 1px solid #3d475c !important;
}
.idPartner label {
  margin-right: 6px !important;
  color: #8ea8cd !important;
}
.dark .bss {
  border-bottom: 1px solid #667085;
}
.dark .ant-picker.ant-picker-range.ant-picker-outlined input::placeholder {
  border: 0 !important;
  color: #8ea8cd !important;
}
.dark .commisionTag button:nth-child(1) {
  border-left: 1px solid #3d475c !important;
}
.dark .ant-picker.ant-picker-range.ant-picker-outlined input {
  border: 0 !important;
  color: #8ea8cd !important;
  background-color: transparent !important;
  font-family: "Inter" !important;
}
.dark .custom-calendar-class .ant-picker-footer-extra {
  position: relative !important;
  border-top: 1px solid #3d475c;
  padding-top: 6px;
  margin-top: 12px;
}
.dark .custom-calendar-class .ant-picker-footer-extra .ant-btn-primary {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  height: 40px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  color: #fff !important;
  font-weight: 600;
  background-color: #155eef !important;
  border: 1px solid #155eef !important;
}

.dark .custom-calendar-class .ant-input {
  border: 1px solid #72849d !important;
  color: #8ea8cd !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 44px !important;
  font-family: "Inter";
  width: 136px;
  margin-right: 28px;
  border-radius: 8px;
  background: transparent !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: "." !important;
  border: 0 !important;
  color: #155eef;
  position: absolute;
  top: 10px;
  font-size: 40px !important;
}

.dark .custom-calendar-class .ant-picker-footer-extra::before {
  content: "";
  background-image: url(./assets/images/Text.png);
  width: 12px !important;
  position: absolute !important;
  height: 2px;
  bottom: 38px;
  left: 156px;
}

.dark .custom-calendar-class .ant-picker-footer-extra .w-full.py-4 {
  display: flex !important;
  align-items: baseline !important;
  padding: 10px 0 0 !important;
  justify-content: space-between !important;
}

.dark .custom-calendar-class .ant-picker-panels {
  padding-top: 12px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.dark .custom-calendar-class .ant-picker-panel-layout {
  padding: 0 14px !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before {
  background-color: #f9fafb !important;
}

.dark
  .custom-calendar-class
  .ant-picker-cell.ant-picker-cell-range-start.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  border-radius: 100px !important;
  background-color: #155eef !important;
  color: #fff !important;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark .custom-calendar-class td.ant-picker-cell .ant-picker-cell-inner {
  color: #8ea8cd;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.dark .custom-calendar-class .ant-picker-year-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
  color: #fff;
}

.dark .custom-calendar-class .ant-picker-month-btn {
  font-size: 16px !important;
  font-weight: 600;
  font-family: "Inter" !important;
  color: #fff;
}

.dark
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  li {
  color: #8ea8cd !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  height: 40px !important;
  display: flex;
  align-items: center;
}

.dark
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container {
  box-shadow: 0px 20px 24px -4px #10182814;
  border: 1px solid #3d475c !important;
  border-radius: 8px !important;
  background-color: #222530 !important;
}

.dark
  .custom-calendar-class
  .ant-picker-range-wrapper.ant-picker-date-range-wrapper
  .ant-picker-panel-container
  .ant-picker-presets {
  width: 170px !important;
}

.dark .innerPartner.views tbody td:nth-child(1) span {
  color: #8ea8cd !important;
  font-family: "Inter";
  font-size: 14px !important;
  font-weight: 500 !important;
}

.light .innerPartner.views tbody td:nth-child(1) span {
  color: #101828 !important;
  font-family: "Inter";
  font-weight: 500 !important;
}

.modalViewPopup.pops .ant-modal-close-x {
  display: none !important;
}

.crseedTick {
  width: 14px !important;
  height: 14px !important;
  position: absolute;
  right: 16px;
  cursor: pointer;
  z-index: 99;
}

.light .innerPartner.views .actionLink button span {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.light .innerPartner.views .actionLink button {
  border: 0 !important;
}

.light .innerPartner.views tbody td:nth-child(2) span {
  color: #101828 !important;
  font-family: "Inter";
  font-weight: 500 !important;
}

.light .innerPartner.views table thead th {
  padding-top: 12px !important;
  padding-bottom: 13px !important;
  background: #f9fafb !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
}

.inviteHead h3 {
  font-size: 18px;
  font-weight: 600;
  color: #101828 !important;
  font-family: "Inter";
}

.inviteHead {
  padding: 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.invitationModal .ant-modal-footer {
  display: none !important;
}

.invitationModal .ant-modal-content {
  padding: 0 !important;
}

.selectInput div {
  height: auto;
  padding-top: 2px;
  font-family: "Inter";
  font-size: 16px;
  border-radius: 8px;
  min-height: 32px;
  color: #000;
  font-weight: 400;
  overflow: hidden;
}

.phoneNumber .ant-input {
  border: 0 !important;
  color: #000 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: "Inter" !important;
}

.selectInput svg {
  position: relative;
  top: 2px;
}

h6.charterPassword {
  color: #475467;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 8px 0 0;
}
.signInput::placeholder {
  color: #667085;
}
.phoneNumber .ant-input::placeholder {
  color: #667085 !important;
}
.copyBtn button {
  border-left: 0 !important;
}
.signInput {
  width: 100%;
  height: 44px;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  color: #101828;
  outline: 0 !important;
  padding: 0 13px;
  text-transform: none !important;
}

.adminHeader {
  position: fixed;
  width: 100%;
  z-index: 99;
}

h1 {
  font-family: "Inter", sans-serif;
}

h2 {
  font-family: "Inter", sans-serif !important;
}

h3 {
  font-family: "Inter", sans-serif !important;
}

h4 {
  font-family: "Inter", sans-serif !important;
}

h5 {
  font-family: "Inter", sans-serif !important;
}

h6 {
  font-family: "Inter", sans-serif !important;
}

p {
  font-family: "Inter", sans-serif !important;
}

.dateRangePicker.yearWise {
  position: relative;
}

.dateRangePicker.yearWise img {
  position: absolute;
  top: 11px !important;
  left: 8px !important;
}

.inputSearch input::placeholder {
  opacity: 1 !important;
  color: #667085 !important;
  font-family: "Inter" !important;
  font-size: 16px !important;
}

.tableBoxed table thead th {
  background: #f9fafb !important;
  padding: 10px 17px !important;
  color: #475467 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.tableBoxed table tbody tr td:nth-child(1) {
  color: #101828 !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tableBoxed table tbody tr td:nth-child(2) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tableBoxed table tbody tr td:nth-child(4) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.tabsBtn button span {
  font-size: 14px !important;
  color: #1d2939 !important;
  font-family: "Inter" !important;
}

.meetingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 34px;
}

.meetingBox h3 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Inter";
}

.leftForm label {
  color: #344054;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 500;
  padding-bottom: 8px;
  display: block;
}

.leftForm input::placeholder {
  color: #667085;
  font-family: "Inter";
}

.leftForm textarea::placeholder {
  color: #667085;
  font-family: "Inter";
}

.container.active .ant-col.ant-col-16 {
  display: none !important;
}

.container.active .ant-col.ant-col-8 {
  flex: 0 0 99.333333% !important;
  max-width: 99.33333333333333% !important;
}

.iframe iframe {
  width: 100% !important;
  height: 700px;
}

.leftForm textarea {
  color: #101828;
  font-family: "Inter";
}
.rsetText {
  color: #475467;
  font-size: 16px;
}
.newpasswords {
  color: #475467 !important;
  font-size: 16px !important;
  padding-top: 5px;
}
.boxedNEw label {
  display: block;
  padding-bottom: 6px;
  font-family: "Inter";
  color: #344054;
  font-size: 14px;
  font-weight: 500;
}
.boxedNEw input {
  color: #101828;
  outline: 0 !important;
}
.boxedNEw button {
  position: relative;
  top: 5px;
}
.boxedNEw input::placeholder {
  color: #667085;
}
.emailTexts {
  font-size: 14px !important;
  color: #475467;
}
.leftForm textarea::placeholder {
  color: #667085 !important;
}
.leftForm input::placeholder {
  color: #667085 !important;
}
.leftForm input {
  color: #101828;
  font-family: "Inter";
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd !important;
  padding: 8px;
  border-radius: 8px !important;
}

.btnSubmit {
  height: 48px;
  width: 95%;
  background: #155eef !important;
  border-color: #155eef !important;
  color: #fff !important;
  font-size: 16px !important;
  font-family: "Inter";
  font-weight: 600;
  border-radius: 8px !important;
}

.boxedContact.active {
  border: 1px solid #84adff !important;
}

.boxedContact {
  cursor: pointer;
  width: 230px;
  height: 163px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  background: #fff;
  padding: 25px 20px;
}

.tabsBtn button {
  height: 40px !important;
}

.tableBoxed table tbody tr td:nth-child(5) {
  color: #475467 !important;
  font-family: "Inter" !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  padding: 20px 18px !important;
}

.inputSearch img {
  position: relative;
  bottom: 1px;
}

input.rmdp-input {
  width: 150px !important;
  height: 40px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #344054 !important;
  padding-left: 36px;
}

.dateBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.labelCard {
  font-size: 14px;
  color: #475467;
  font-family: "Inter";
}

.copyBtn input {
  border: 1px solid #d0d5dd !important;
  color: #667085;
  font-weight: 700 !important;
  font-family: "Inter", sans-serif !important;
}

span {
  font-family: "Inter", sans-serif !important;
}

.headingText {
  font-weight: 700 !important;
}

.logoImg img {
  margin: 0 auto !important;
}

.phoneNumber .ant-select-selection-item {
  background: transparent !important;
  /* width: 47px !important;
  max-width: 47px !important; */
  padding: 0 !important;
}

.phoneNumber .ant-select-arrow {
  /* position: relative; */
  left: 31px !important;
}

.apexcharts-inner {
  position: relative !important;
  transform: translate(34px, 37px) !important;
}

.apexcharts-legend {
  right: 16px !important;
}

.rightBoxed .right {
  font-size: 12px !important;
}

.dark .showDate span {
  color: #fff !important;
}

.light .border.showDate {
  width: auto;
  height: 40px;
  border: 1px solid #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
  border-radius: 8px !important;
}

.light .partnerBox button {
  height: 40px;
  font-family: "Inter";
  border: 1px solid #d0d5dd !important;
  font-size: 14px !important;
  color: #344054 !important;
  font-weight: 600 !important;
}
.text-red-500 {
  font-family: "Inter";
}
.dark .passwordTheme .ant-input-affix-wrapper input::placeholder {
  color: #72849d !important;
}
.dark .innerPartner.views table {
  background-color: #222530 !important;
}
.dark .notificationBoxed .textRight h5 {
  color: #4885ff !important;
}
.light .partnerTable.edits tbody td:nth-child(1) {
  color: #475467 !important;
  font-size: 14px !important;
}

.border.showDate .flex.items-center.gap-3 {
  gap: 0.65rem !important;
}
.dark
  .custom-calendar-class
  .ant-picker-cell.ant-picker-cell-in-range.ant-picker-cell-in-view::before {
  background: #2f4f91 !important;
}
.dark
  td.ant-picker-cell.ant-picker-cell-in-range.ant-picker-cell-in-view
  .ant-picker-cell-inner {
  background: #2f4f91 !important;
  color: #fff !important;
}
.light .border.showDate span {
  color: #667085 !important;
  font-size: 14px !important;
  white-space: nowrap;
}

.graphHeader {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 26px 20px 10px 5px !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.linkBoxed {
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
  border-radius: 12px;
  margin: 30px 0;
  padding: 0 !important;
}

.graphHeader h3 {
  color: #475467;
  font-size: 16px;
  font-weight: 500;
  font-family: "Inter";
}

.graphHeader h6 {
  color: #101828;
  font-size: 30px;
  font-weight: 600;
  font-family: "Inter";
}

.cardBoxed {
  padding: 0 !important;
}

.dashboardCard {
  height: 96px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 3px 0px #1018281a;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 0 21px;
}

.worries {
  color: #475467;
  font-size: 16px;
  font-weight: 400;
}

.forgotText {
  font-family: "Inter";
  color: #101828;
  text-align: center;
  line-height: 38px;
  padding: 22px 0 7px;
}
.dark .phoneNumber .ant-select-selector {
  background-color: #3d475c !important;
}
.phoneNumber .ant-select-selector {
  padding: 0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding-left: 4px !important;
  color: #667085 !important;
  text-align: left !important;
  background: #efefef !important;
  height: 24px !important;
  top: 4px;
}
.dark .phoneNumber .ant-input-group-wrapper input::placeholder {
  color: #667085 !important;
}
.light .phoneNumber .ant-select-selector {
  padding: 0 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding-left: 4px !important;
  color: #667085 !important;
  text-align: left !important;
  background: #efefef !important;
  height: 24px !important;
  top: 4px;
}

.phoneNumber .ant-select {
  width: 47px !important;
}

.phoneNumber .ant-input-group-wrapper {
  border: 1px solid #d0d5dd;
  padding: 5px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.phoneNumber .ant-input-group-addon {
  width: 56px !important;
  max-width: 58px !important;
  background-color: transparent !important;
  padding: 0 !important;
  height: 24px !important;
  border: 0 !important;
}

.bg_img {
  background-image: url(../src/assets/images/login.png);
  height: 100%;
  position: fixed;
  width: 50%;
  background-size: 100%;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
  top: 20px !important;
}

.apexcharts-legend-marker {
  border-radius: 100px !important;
  position: relative;
  top: 2px !important;
  margin-right: 6px !important;
}

.listData {
  position: relative;
  display: flex;
  align-items: center;
}

.commisionTag button {
  border-left: 0 !important;
  font-size: 14px !important;
}

.dateSelect {
  font-size: 14px;
  color: #475467;
  font-weight: 400 !important;
  font-family: "Inter";
}

.tabsSelect button {
  color: #667085;
  font-size: 16px;
  font-weight: 600 !important;
  font-family: "Inter" !important;
  text-transform: capitalize !important;
}

.partnerTable tbody tr td {
  font-size: 14px !important;
  height: 72px;
  color: #475467 !important;
  font-weight: 500;
  font-family: "Inter" !important;
  color: #101828 !important;
}

.partnerName {
  display: flex;
  align-items: center;
}

.partnerHead h4 {
  color: #101828;
  font-size: 18px;
  font-weight: 600;
  font-family: "Inter";
}

.partnerHead {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  padding: 24px 0 12px;
}

.partnerHead button img {
  margin-right: 6px;
}

.innerPartner thead tr th {
  background: #f9fafb !important;
  color: #475467 !important;
  font-size: 12px !important;
  font-family: "Inter" !important;
  font-weight: 500 !important;
}

.innerPartner tbody tr td {
  color: #475467 !important;
  font-size: 14px !important;
  font-family: "Inter" !important;
  font-weight: 400;
}

.removeBtn {
  text-align: end;
  padding: 24px 20px;
}

.removeBtn button {
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd;
  width: 94px;
  height: 44px;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  font-family: "Inter";
  border-radius: 8px;
  margin-left: 12px;
}

.removeBtn button:hover {
  background-color: #d92d20 !important;
  color: #fff !important;
  border: 1px solid #d92d20 !important;
}

body .modalViewPopup.newRemove button.ant-modal-close {
  top: 27px !important;
}

.modalViewPopup.newRemove .headerView {
  border: 0 !important;
}

.headerView h5 {
  font-family: "Inter";
  color: #101828;
  font-size: 18px;
  font-weight: 600;
}

.headerView {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eff1f4;
}

.headerView img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin-right: 16px;
}

.pText {
  font-family: "Inter";
  color: #475467;
  font-size: 14px;
  font-weight: 400;
}

.modalViewPopup .ant-modal-close {
  top: 47px !important;
}

.modalViewPopup {
  width: 593px !important;
  border-radius: 12px !important;
}

.headerView {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #eff1f4;
}

.headerView img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  margin-right: 16px;
}

.headerView h6 {
  color: #667085 !important;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  line-height: 20px;
  padding: 6px 0;
}

.modalViewPopup .ant-modal-footer {
  display: none !important;
}

.gridBox label {
  font-size: 14px;
  display: block;
  font-family: "Inter";
  padding-bottom: 6px;
  font-weight: 500;
}

.gridBox input::placeholder {
  font-family: "Inter";
  color: #667085;
}

.dropBoxSelect .MuiFormControl-root fieldset {
  border-color: #d0d5dd !important;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d !important;
}

.dropBoxSelect .MuiFormControl-root .MuiInputBase-root input {
  height: auto !important;
  background: transparent !important;
  margin: 0 !important;
  border: 0 !important;
}

.recentBox {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 2px 0 #1018280f;
  box-shadow: 0 1px 3px 0 #1018281a;
  margin: 0px 0 10px;
  padding: 18px !important;
  width: 100%;
}

.modalBtn {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
  /* border-top: 1px solid #EAECF0; */
}

.modalBtn button {
  width: 100% !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border-radius: 8px;
  height: 44px;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-size: 16px;
  font-weight: 600;
  margin: 0 4px;
}

.dropBoxSelect {
  height: 44px !important;
  margin-bottom: 9px !important;
}

.dropBoxSelect .MuiFormControl-root {
  height: 44px !important;
}

.dropBoxSelect .MuiFormControl-root .MuiInputBase-root {
  height: 44px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.gridBox input {
  height: 44px;
  background: #f9fafb;
  box-shadow: 0px 1px 2px 0px #1018280d;
  border: 1px solid #d0d5dd !important;
  border-radius: 8px;
  margin-bottom: 12px;
  font-family: "Inter";
  color: #667085;
}

.gridBox {
  padding: 20px !important;
}

.headerView h3 {
  color: #101828 !important;
  font-size: 24px;
  font-weight: 500;
  font-family: "Inter";
  line-height: 20px;
  padding-top: 11px;
}

.modalViewPopup .ant-modal-content {
  padding: 0 !important;
}

.modalBtn button:hover {
  background-color: #155eef !important;
  color: #fff !important;
  border: 1px solid #155eef !important;
}

.modalViewPopup.newRemove .ant-modal-content {
  padding: 0 !important;
}

.modalViewPopup.newRemove .ant-modal-footer {
  display: none !important;
}

.dark .actionLink button span {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter" !important;
}

.light .actionLink button span {
  color: #475467;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter" !important;
}

.actionLink {
  text-align: end !important;
}

.inviteTag {
  height: 24px !important;
  margin: 0 !important;
  width: 68px !important;
  background: #e8e8e8 !important;
  border: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #525252;
  font-size: 14px;
  font-weight: 500;
}

.light .innerPartner tbody tr td:nth-child(2) {
  color: #101828 !important;
  font-weight: 500 !important;
}

.light .innerPartner tbody tr td:nth-child(1) {
  color: #101828 !important;
  font-weight: 500 !important;
}

.dark .innerPartner.views tbody td:nth-child(2) {
  color: #fff !important;
}

.partnerHead button {
  background-color: #155eef !important;
  border: 1px solid #155eef !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  width: 156px;
  height: 36px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #fff !important;
  display: flex;
  align-items: center;
}

.partnerName img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 100px;
  margin-right: 10px;
}

.partnerTable thead tr th {
  background: #f9fafb !important;
  font-family: "Inter" !important;
  font-size: 12px !important;
  color: #475467 !important;
  font-weight: 500 !important;
  padding: 13px 18px !important;
}

.tabsSelect {
  border-bottom: 1px solid #eaecf0 !important;
  margin: 22px 0 33px !important;
}

.linkBoxed.bars .apexcharts-series:nth-child(2) {
  transform: translate(8px, 0px) !important;
}

.commisionTag button:nth-child(1) {
  border-left: 1px solid #d0d5dd !important;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px;
}

.dateRange {
  border: 1px solid #d0d5dd !important;
  width: 96px;
  height: 40px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  position: relative;
  border-radius: 8px;
}

.dateRange .ant-picker-input {
  flex-flow: row-reverse !important;
}

.dark .dateRange .ant-picker-suffix {
  margin: 0 !important;
  background-image: url(../src/assets/images/datedark.png) !important;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
}

.dateRange .ant-picker-suffix {
  margin: 0 !important;
  background-image: url(../src/assets/images/date.png) !important;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  position: relative;
  top: 2px;
}

.dateRange .ant-picker-suffix span svg {
  display: none !important;
}

.listData img {
  width: 48px;
  height: 48px;
  border-radius: 100px;
  margin-right: 10px;
}

.listedUser {
  margin-bottom: 20px;
}

.listData::before {
  content: "";
  width: 2px;
  height: 16px;
  background: #eaecf0;
  position: absolute;
  left: 23px;
  bottom: -18px;
}

.listData h6 {
  color: #475467;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}

.listData h6 span {
  font-weight: 700;
}

.listData h4 {
  font-size: 14px;
  color: #344054;
  font-weight: 500;
  font-family: "Inter";
}

.recentH4 {
  font-size: 24px;
  color: #101828;
  font-weight: 600;
  margin-bottom: 20px;
}

.linkBoxed.bars {
  padding: 0 8px 0 20px !important;
}

.graphHeader h6 span {
  font-size: 16px !important;
  font-weight: 400 !important;
}

.month {
  text-align: center;
  position: relative;
  bottom: 28px;
}

.linkBoxed.bars .apexcharts-yaxis {
  display: block !important;
  transform: translate(10px, -12px) !important;
}

.apexcharts-yaxis {
  display: none !important;
}

.custom-steps .ant-steps-item-content {
  background: transparent !important;
}

.navbar {
  background-color: white;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.custom-drawer .ant-drawer-header {
  display: flex;
  justify-content: space-between;
  /* Align items to opposite ends */
}

.custom-drawer .ant-drawer-title {
  order: 2;
  /* Move the title to the right */
}

.custom-modal .ant-modal-content {
  padding: 0;
}

.ant-dropdown {
  box-shadow: none;
  padding: 0;
}

.ant-dropdown-menu {
  border: 1px solid #eaecf0 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814 !important;
  width: auto;
  min-width: 240px;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.ant-dropdown-menu-item:hover {
  background-color: red;
}
